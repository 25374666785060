import { render, staticRenderFns } from "./StorageLocationChangeLogsDetailsView.vue?vue&type=template&id=20a5fe84&scoped=true&"
import script from "./StorageLocationChangeLogsDetailsView.vue?vue&type=script&lang=js&"
export * from "./StorageLocationChangeLogsDetailsView.vue?vue&type=script&lang=js&"
import style0 from "./StorageLocationChangeLogsDetailsView.vue?vue&type=style&index=0&id=20a5fe84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5fe84",
  null
  
)

export default component.exports